import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37388680"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "poster"]
const _hoisted_2 = ["poster"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "w-100 h-100",
    id: "video-iframe",
    style: _normalizeStyle(_ctx.style)
  }, [
    (_ctx.videoSrc || _ctx.source)
      ? (_openBlock(), _createElementBlock("iframe", {
          key: 0,
          src: _ctx.videoSrc || _ctx.source,
          ref: "videoPlayer",
          class: "video-js w-100 h-100",
          controls: "",
          poster: _ctx.videoPoster,
          height: "100%",
          style: {"border-radius":"10px","height":"100%"}
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createElementBlock("video", {
          key: 1,
          style: {"border-radius":"10px"},
          ref: "videoPlayer",
          class: "video-js w-100",
          controls: "",
          poster: _ctx.videoPoster
        }, [
          _createElementVNode("source", {
            src: _ctx.videoSrc,
            type: "video/mp4"
          }, null, 8, _hoisted_3),
          _createTextVNode(" Sorry, your browser doesn't support embedded videos. ")
        ], 8, _hoisted_2))
  ], 4))
}